<template>
	<div class="table">
		<el-table
			border
			ref="multipleTable"
			id="exportTab"
			:data="tableData"
			:header-cell-style="{ 'text-align': 'center' }"
			height="60vh"
			:cell-style="{ 'text-align': 'center' }"
			tooltip-effect="dark"
			style="width: 100%"
		>
			<el-table-column prop="wz_id" label="id" width="100px"> </el-table-column>
			<el-table-column prop="wz_title" label="资讯标题" show-overflow-tooltip>
			</el-table-column>
			<el-table-column prop="yyr" label="统计时间" width="150px">
			</el-table-column>
			<el-table-column prop="pv" label="PV" width="150px"> </el-table-column>
			<el-table-column prop="uv" label="UV" width="150px"> </el-table-column>
		</el-table>
	</div>
</template>

<script>
import { disInformation, delInformation, informationHome } from "@/api/article";
export default {
	props: ["tableData"],
	name: "",
	data() {
		return {
			value: true,
		};
	},
	methods: {
		showPreView(row) {
			let url = "https://metaera.hk/contents/" + row.id;
			if (row.type == 2) {
				url = "https://metaera.hk/news/" + row.id;
			}
			window.open(url, "预览");
		},
	},
};
</script>

<style scoped></style>
